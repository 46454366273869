(function () {

    document.addEventListener("DOMContentLoaded", () => {
        const testimonialSliderEl = document.getElementById('testimonials-slider');

        var testimonialSlider = new Swiper(testimonialSliderEl, {
            slidesPerView: 1,
            speed: 750,
            spaceBetween: 60,
            autoplay: {
                delay: 4000,
                disableOnInteraction: true
            },
            pagination: {
                el: ".testimonial-pagination",
                clickable: true
            },
            breakpoints: {
                768: {
                    speed: 1000,
                }
            }
        });

    });
}());